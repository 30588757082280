import { useState, useEffect } from "react";
import logo from "./logo.png";
import admLogo from "./ADM-logo.png";

import { ReactComponent as IconSuccess } from "./icon-success.svg";
import { ReactComponent as IconError } from "./icon-error.svg";
import "./App.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactJson from "react-json-view";
import { BASE_URL, LOGIN } from "./costants";
import { useForm } from "react-hook-form";

function App() {
  const [verifyState, setVerifyState] = useState({
    txId: null,
    project: null,
    token: null,
    transaction: null,
    notFound: false,
    isLoading: false,
    content: null,
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const login = async () => {
    try {
      const result = await axios.post(`${BASE_URL}/login`, LOGIN);
      const searchParams = new URLSearchParams(window.location.search);
      setVerifyState({
        ...verifyState,
        txId: searchParams.get("txId"),
        project: searchParams.get("project"),
        content: searchParams.get("content"),
        token: result.data.token,
      });
    } catch (e) {
      toast.error("Error during authentication");
    }
  };

  const searchTransaction = async () => {
    setVerifyState({
      ...verifyState,
      isLoading: true,
    });

    try {
      const config = {
        headers: {
          Authorization: verifyState.token,
        },
      };

      const result = await axios.get(
        `${BASE_URL}/p/${verifyState.project}/notary/${verifyState.txId}`,
        config
      );

      try {
        const parsedContent = JSON.parse(result.data.content);
        result.data.content = parsedContent;
      } catch (e) {
        console.log(e);
      }

      setVerifyState({
        ...verifyState,
        transaction: result.data,
        notFound: false,
        isLoading: false,
      });
    } catch (e) {
      setVerifyState({
        ...verifyState,
        transaction: null,
        notFound: true,
        isLoading: false,
      });
    }
  };

  const onSearchClick = data => {
    setVerifyState({
      ...verifyState,
      project: data.project,
      txId: data.txId,
    });
  };

  const checkContent = () => {
    const verified = verifyState.content === verifyState.transaction.content;

    return (
      <div className={`verification-alert ${verified ? "success" : "error"}`}>
        {verified ? (
          <>
            <IconSuccess /> Content verified
          </>
        ) : (
          <>
            <IconError /> Content mismatch
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    !verifyState.token && login();
    verifyState.token &&
      verifyState.txId &&
      verifyState.project &&
      searchTransaction();
  }, [verifyState.txId, verifyState.project]);

  return (
    <div className="verify-transaction">
      <ToastContainer />
      <div className="transaction-header">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
      </div>

      <div className="transaction-container ">
        <h2>Verify transaction </h2>

        <div className="search-transaction">
          <div className="form-row">
            <div className="form-item">
              <label>Project Id </label>
              <input
                type="text"
                name="project"
                placeholder="Insert project Id"
                defaultValue={verifyState.project}
                {...register("project", { required: true })}
              />
            </div>
            <div className="form-item">
              <label>Transaction Id </label>
              <input
                type="text"
                name="txId"
                placeholder="Insert transaction Id"
                defaultValue={verifyState.txId}
                {...register("txId", { required: true })}
              />
            </div>
          </div>
          <div>
            <button
              className=""
              onClick={handleSubmit(onSearchClick)}
              disabled={!isValid}
            >
              Verify
            </button>
          </div>
        </div>
        {verifyState.isLoading && "Loading"}
        {!verifyState.isLoading &&
          verifyState.notFound &&
          "Transaction not found"}
        {!verifyState.isLoading && verifyState.transaction && (
          <div className="verify-container">
            <ReactJson
              src={verifyState.transaction}
              theme="monokai"
              displayDataTypes={false}
              name="notary"
              style={{
                overflow: "hidden",
                padding: "1rem",
                borderRadius: "1rem",
                fontSize: "1.1rem",
              }}
            />

            {verifyState.content && checkContent()}
          </div>
        )}
      </div>

      <div className="transaction-footer">
        <div className="logo">
          <img src={admLogo} alt="Logo" />
        </div>
      </div>
    </div>
  );
}

export default App;
